import { useState, useEffect, Fragment } from "react";
import { Link, useNavigate, useParams } from "@reach/router";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import cx from "classnames";
import isEqual from "lodash/isEqual";
import { v4 } from "uuid";
import ReactTooltip from "react-tooltip";

import { formatDate } from "config/i18n";
import useQuery from "hooks/useQuery";
import getAccessToken from "hooks/getAccessToken";
import useMutationCustom from "hooks/useMutation";
import Country from "Components/Country/Country";
import Address from "Components/Address/Address";
import useUser from "hooks/useUser";
import Form from "../../Form/Form";
import Input from "../../Form/Input";
import EventForm from "../../Events/Form/Form";
import Emails from "../../Form/Emails/Emails";
import Text from "../../Form/Text";
import Phones from "../../Form/Phones/Phones";
import Financiers from "../../Form/Financiers/Financiers";
import Select from "../../Form/Select";
import useStoreRooms from "../../Room/useStore";
import verifyConsumer from "../validateConsumer";

const { REACT_APP_API_URL } = process.env;
let clone = window.structuredClone || JSON.clone

const fetchMutate = (token) => (payload) => {
  const url = `${REACT_APP_API_URL}${window._l(`/consumers/merge`)}`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

const Event = ({ event, consumer }) => {
  const { t } = useTranslation();
  const openRoom = useStoreRooms((state) => state.open);
  const [form, setForm] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <div className="bg-white shadow px-4 py-3 rounded mt-4 d-flex align-items-center justify-content-between">
        <div>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-link p-0 text-first-up"
              onClick={() => setForm(!form)}
            >
              {formatDate(event.startsAt, "EEEE d MMMM yyyy HH:mm")}
            </button>
            {event.remote && (
              <button
                data-tip={t("start_remote_service", "Iniciar videoconsulta")}
                className="btn btn-link ms-1 p-0"
                onClick={() => {
                  openRoom(`${event.id}/${event.token}`);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-camera-video-fill text-dark ms-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
                  />
                </svg>
              </button>
            )}
            {event.source !== "drmanager" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="bi ms-2"
                data-tip={t(`source_${event.source}`)}
                height="16"
                width="16"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
          <div>
            <div
              className={cx({
                "badge bg-warning": event.status === "arrived",
                "badge bg-primary": event.status === "active",
                "badge bg-success": event.status === "fulfilled",
                "badge bg-danger": ["cancelled", "noshow"].includes(
                  event.status
                ),
                "badge bg-dark": event.status === "booked",
              })}
            >
              {t(`status_${event.status}`)}
            </div>
          </div>
        </div>
        <div className="text-end">
          <div>{t(event.service.key, event.service.label)}</div>
          <div className="text-secondary">{event.resource.label}</div>
        </div>
      </div>
      {form && (
        <div
          className="p-4 mt-3 shadow bg-white rounded"
          style={{ width: "390px" }}
        >
          <EventForm
            eventID={event.id}
            onClose={() => setForm(false)}
            onChange={(newEvent) => setForm(false)}
            consumer={consumer}
          />
        </div>
      )}
    </>
  );
};

const Candidate = (props) => {
  const { t, i18n } = useTranslation();
  const accessToken = getAccessToken();
  const [merged, setMerged] = useState(false);
  const { mutate } = useMutation(fetchMutate(accessToken.value), {
    onMutate: (payload) => {
      setMerged(true);
    },
    onSuccess: (data) => {
      if (data.id !== props.consumerID) {
        props.onSelect(data.id);
      }
    },
  });

  const merge = () => {
    mutate([props.consumerID, props.candidate.id]);
  };

  const select = () => {
    props.onSelect(props.candidate.id);
  };

  return (
    <div className="d-flex align-items-center justify-content-between bg-light-blue px-3 py-2">
      <div>
        <div>
          <a
            href={window._l(`/${props.candidate.id}`)}
            target={encodeURIComponent(props.candidate.id)}
          >
            {props.candidate.label}
          </a>
        </div>
        <small className="text-secondary">
          {parseInt(
            props.candidate.identification?.replace(/\D/g, "")
          ).toLocaleString("es")}
        </small>
      </div>
      {!merged ? (
        <div>
          <button
            type="button"
            className="btn btn-sm bg-warning text-dark"
            data-tip={t("merge_select_tip", "Carga los datos de este paciente")}
            onClick={select}
          >
            {t("select")}
          </button>
          {props.consumerID && (
            <button
              type="button"
              data-tip={t(
                "merge_consumer_tip",
                "Al fusionar los pacientes quedan unificados todos los datos del paciente en uno solo"
              )}
              className="btn btn-sm bg-warning text-dark ms-2"
              onClick={merge}
            >
              {t("merge_consumer", "Fusionar")}
            </button>
          )}
        </div>
      ) : (
        <div className="text-sm text-success">{t("merged", "fusionado")}</div>
      )}
    </div>
  );
};

const App = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const user = useUser();
  const [consumerID, setConsumerID] = useState(null);
  const [warnings, setWarnings] = useState(props.warnings || {});

  const getFinancierWarnings = (object) => {
    return clone({ financiers: object?.financiers, flag: object?.financiers?.flag })
  }

  const [current, setCurrent] = useState({
    firstName: "",
    lastName: "",
    occupation: "",
    address: "",
    dob: "",
    country: "",
    identification: "",
    gender: "",
    phones: [{}],
    emails: [{}],
    financiers: [{}],
    ...props.initialState,
  });

  const candidates = useQuery(window._l(`/search/consumers`).substr(1), {
    initialData: [],
    enabled: current.identification?.length >= 6,
    body: { q: current.identification, sort: { key: "label" }, exactIdentificationMatch: true },
  });

  const _candidates = candidates.data.filter(
    (candidate) => consumerID !== candidate.id
  );

  const [original, setOriginal] = useState(clone(current));

  const events = useQuery(window._l(`/${consumerID}/events`).substr(1), {
    initialData: { past: [], next: [] },
    enabled: !!consumerID && props.events !== false,
  });

  const query = useQuery(window._l(`/${consumerID}`).substr(1), {
    enabled: !!consumerID,
  });

  const [mutate, mutation] = useMutationCustom(
    window._l(`/${consumerID || "consumers"}`).substr(1),
    {
      onMutate: (payload) => {
        payload.label = [payload.lastName, payload.firstName]
          .filter(Boolean)
          .join(", ");
        setOriginal(clone(payload));
        setCurrent(clone(payload));
      },
      onSuccess: (data) => {
        if (props.onSave) {
          props.onSave(clone(data));
        } else if (props.callback) {
          props.callback(clone(data));
        } else if (!consumerID && data.id) {
          navigate(`../${data.id}`);
        }
      },
      onError: (error) => {
        window.notify({
          message: error.message,
          type: "danger",
          title: "Error",
        });
      },
    }
  );

  const disabled = consumerID && !user.actions.can("write", current);
  const loading = query.isLoading || mutation.isLoading;
  const changed =
    !isEqual(original, current) || !current.id || /^new/.test(current.id);
  isEqual(original, current);

  const toggle = () => {
    if (disabled) return;
    current.deleted = !current.deleted;
    setCurrent({ ...current });
    mutate(current);
  };

  const onChange = ({ target: { name, value, type } }) => {
    if (value && type === "number") {
      current[name] = +value;
    } else if (type === "checkbox") {
      current[name] = !current[name];
    } else {
      current[name] = value;
    }

    let warnings = verifyConsumer(current);
    if(props.source !== "prescription"){
      warnings = getFinancierWarnings(warnings);
    }
    setWarnings(warnings)
    setCurrent(clone(current));
  };

  const onSelectCandidate = (candidateID) => {
    if (params?.consumerID) {
      navigate(`../${candidateID}`);
    } else {
      setConsumerID(candidateID);
    }
  };

  const onChangeFinancier = (index) => ({ target: { name, value, type } }) => {
    if (value && type === "number") {
      current.financiers[index][name] = +value;
    } else if (type === "checkbox") {
      current.financiers[index][name] = !current.financiers[index][name];
    } else {
      current.financiers[index][name] = value;
    }
    onChange({
      target: { name: "financiers", value: [...current.financiers] },
    });
  };

  const onSubmit = () => {
    if (disabled) return;
    mutate(current);
  };

  useEffect(() => {
    if (query.data?.id) {
      const original = clone(query.data);
      if (original.emails.length === 0) {
        original.emails.push({});
      }
      if (original.phones.length === 0) {
        original.phones.push({});
      }
      if (original.financiers.length === 0) {
        original.financiers.push({});
      }
      const current = clone(original);
      setCurrent(current);
      setOriginal(original);
    }
  }, [query.data]);

  useEffect(() => {
    if (query.data?.id && !props.warnings) {
      let warnings = verifyConsumer(query.data);
      if(props.source !== "prescription"){
        warnings = getFinancierWarnings(warnings);
      }
      setWarnings(warnings);
    }
  }, [query.data]);

  useEffect(() => {
    if (props.consumerID === "new" || !props.consumerID) {
      setConsumerID(null);
    } else if (/^consumers\//.test(props.consumerID)) {
      setConsumerID(props.consumerID);
    } else {
      setConsumerID(`consumers/${props.consumerID}`);
    }
  }, [props.consumerID]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (disabled) {
    return (
      <div
        className={[
          "d-flex",
          "flex-column",
          "justify-content-center",
          "align-items-center",
          props.className,
        ]
          .filter(Boolean)
          .join(" ")}
      >
        <div style={{ width: "480px" }}>
          <div className="card bg-warning">
            <div className="card-header">{t("request_access_title")}</div>
            <div className="card-body">
              <h5 className="card-title">{current.label}</h5>
              <p className="card-text">{t("request_access_message")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        skip={!changed}
        className={[props.className].filter((p) => p).join(" ")}
      >
        <div>
          <Input
            autoFocus={!current.firstName}
            warning={!!warnings.firstName}
            label={t("firstName", { context: "consumers" })}
            disabled={disabled}
            autoCapitalize="words"
            required
            minLength="2"
            maxLength="32"
            pattern="[A-Za-zÀ-ÖØ-öø-ÿ .]{2,32}"
            type="text"
            name="firstName"
            id="firstName"
            placeholder={t("firstName_placeholder", {
              context: "consumers",
            })}
            value={current.firstName || ""}
            onChange={onChange}
            hint={t("firstName_hint", { context: "consumers" })}
          />


          <Input
            warning={!!warnings.lastName}
            label={t("lastName", { context: "consumers" })}
            disabled={disabled}
            autoCapitalize="words"
            required
            minLength="2"
            maxLength="32"
            pattern="[A-Za-zÀ-ÖØ-öø-ÿ .]{2,32}"
            type="text"
            name="lastName"
            id="lastName"
            placeholder={t("lastName_placeholder", {
              context: "consumers",
            })}
            value={current.lastName || ""}
            onChange={onChange}
            hint={t("lastName_hint", { context: "consumers" })}
          />

          <Input
            warning={!!warnings.identification}
            label={t("identification", { context: "consumers" })}
            disabled={disabled}
            minLength="4"
            maxLength="32"
            type="text"
            name="identification"
            id="identification"
            placeholder={t("identification_placeholder", {
              context: "consumers",
            })}
            value={current.identification || ""}
            onChange={onChange}
            hint={t("identification_hint", { context: "consumers" })}
          />
          {current.identification?.length >= 6 &&
            !mutation.isLoading &&
            _candidates.length > 0 && (
              <div className="border rounded shadow-sm mb-3 overflow-hidden">
                <h6 className="px-3 pt-3">
                  {t(
                    "consumer_canidates_same_identification",
                    "Pacientes con el mismo DNI"
                  )}
                </h6>
                {_candidates.map((candidate) => (
                  <Candidate
                    key={candidate.id}
                    candidate={candidate}
                    consumerID={consumerID}
                    onSelect={onSelectCandidate}
                  />
                ))}
              </div>
            )}

          <Select
            label={t("gender")}
            disabled={disabled || /dr|dra/g.test(current.namePrefix)}
            id="gender"
            name="gender"
            value={current.gender || ""}
            onChange={onChange}
            placeholder={t("gender_placeholder")}
            options={["female", "male", "other"].map((value) => ({
              value,
              label: t(value),
            }))}
            hint={t("gender_hint", { context: "consumers" })}
          />

          <Input
            disabled={disabled}
            minLength="6"
            maxLength="8"
            type="date"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            name="dob"
            id="dob"
            placeholder={t("dob_placeholder")}
            value={current.dob || ""}
            onChange={onChange}
            label={t("dob")}
            hint={t("dob_hint")}
            formats={["no-inputtype-date"]}
          />

          <div className="mb-3">
            <label className="form-label" htmlFor="country">
              {t("country", { context: "consumers" })}
            </label>
            <Country
              name="country"
              id="country"
              required
              value={current.country || ""}
              onChange={(country) =>
                onChange({
                  target: {
                    name: "country",
                    value: country,
                  },
                })
              }
            />
            <div
              className="form-text"
              dangerouslySetInnerHTML={{
                __html: t(`country_hint`, { context: "consumers" }),
              }}
            />
          </div>

          <div className="mb-3">
            <div className="d-flex align-items-center form-label">
              <label htmlFor="financierValue" className={!!warnings.financiers?.flag ? 'fw-bold text-danger' : ''}>
                {t("financier_plural", { context: "consumers" })}
              </label>
              {!disabled && (
                <button
                  onClick={() =>
                    setCurrent({
                      ...current,
                      financiers: [...current.financiers, { id: v4(), label: '' }],
                    })
                  }
                  type="button"
                  className="btn btn-link p-0 ms-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-square-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                  </svg>
                </button>
              )}
            </div>
            {current.financiers &&
              current.financiers.map((financier, index) => {
                const financierWarnings = warnings?.financiers && warnings.financiers[financier.id];
                return (
                  <div className="mb-2">
                    <div
                      className="d-flex"
                      key={`consumer-financier-${financier.id}-${index}`}
                    >
                      <div className="flex-fill">
                        <Financiers
                          any={false}
                          inputID="financierValue"
                          clearOnSelect={false}
                          value={financier}
                          onChange={(payload) => {
                            current.financiers[index] = {
                              ...financier,
                              ...payload,
                            };
                            onChange({
                              target: {
                                name: "financiers",
                                value: [...current.financiers],
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="flex-md-fill ms-2 position-relative">
                        <input
                          disabled={disabled}
                          autoCapitalize="words"
                          type="text"
                          name="identification"
                          placeholder={t("identification_placeholder", {
                            context: "consumers_financiers",
                          })}
                          value={financier.identification || ""}
                          onChange={onChangeFinancier(index)}
                          className={`form-control ${(financierWarnings?.identification || financierWarnings?.identification_format)
                              ? 'border-danger bg-danger-light focus-ring-danger'
                              : ''
                            }`}
                        />
                        {!disabled && (
                          <div className="form-control-actions d-flex align-items-center">
                            <button
                              className="btn btn-sm text-danger py-0 px-1"
                              type="button"
                              onClick={() => {
                                setCurrent({
                                  ...current,
                                  financiers: current.financiers.filter(
                                    (_, _index) => _index !== index
                                  ),
                                });
                              }}
                            >
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="bi bi-x"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                                />
                                <path
                                  fillRule="evenodd"
                                  d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {financierWarnings?.identification_format && (
                      <div className="form-text text-danger">{t("financier_format_warning", "El número de afiliado de OSDE debe ser de 11 dígitos")}</div>
                    )}
                  </div>
                )
              })}
          </div>

          <Emails
            value={current.emails || []}
            disabled={disabled}
            onChange={(emails) =>
              onChange({ target: { name: "emails", value: emails } })
            }
            context="consumers"
            options={{ event: "all" }}
          />

          <Phones
            value={current.phones || []}
            disabled={disabled}
            onChange={(phones) =>
              onChange({ target: { name: "phones", value: phones } })
            }
            context="consumers"
          />

          <Input
            label={t("occupation", 'Ocupación', { context: "consumers" })}
            disabled={disabled}
            type="text"
            name="occupation"
            id="occupation"
            placeholder={t("occupation_placeholder", {
              context: "consumers",
            })}
            value={current.occupation || ""}
            onChange={onChange}
            hint={t("occupation_hint", 'Ocupación', { context: "consumers" })}
          />

          <Input
            label={t("address", 'Dirección', { context: "consumers" })}
            disabled={disabled}
            type="text"
            name="address"
            id="address"
            placeholder={t("address_placeholder", {
              context: "consumers",
            })}
            value={current.address || ""}
            onChange={onChange}
            hint={t("address_hint", 'Dirección', { context: "consumers" })}
          />

          <Text
            label={t("notes", { context: "consumers" })}
            id="notes"
            name="notes"
            value={current.notes || ""}
            onChange={onChange}
            placeholder={t("notes_placeholder", { context: "consumers" })}
            rows={1}
            hint={t("notes_hint", { context: "consumers" })}
          />
        </div>

        <div className={cx("bg-white mt-4", { "sticky-bottom": changed })}>
          <div className="d-flex align-items-center justify-content-end">
            {loading ? (
              <button type="button" className="btn btn-secondary ms-3" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                {t("loading")}
              </button>
            ) : (
              <Fragment>
                {props.onClose ? (
                  <button
                    type="button"
                    onClick={() => props.onClose({ ...current })}
                    className="btn btn-link link-dark"
                    data-bs-dismiss={props.modal === false ? '' : 'modal'}
                  >
                    {t("back")}
                  </button>
                ) : props.callback ? (
                  <button
                    type="button"
                    onClick={() => props.callback({ ...current })}
                    className="btn btn-link link-dark"
                    data-bs-dismiss={props.modal === false ? '' : 'modal'}
                  >
                    {t("back")}
                  </button>
                ) : props.back !== false && (
                  <Link className="btn btn-link link-secondary" to="../">
                    {t("back")}
                  </Link>
                )}

                {current.id && !changed && (
                  <div disabled className="btn btn-link link-secondary">
                    {t("all_saved")}
                  </div>
                )}

                {props.ehr !== false && current.id &&
                  user.actions.has("ehr") &&
                  user.team?.resourceID && (
                    <Link
                      className="btn btn-link"
                      to={window._l(`/${current.id}/ehr`)}
                    >
                      {t("ehr", { context: "patients" })}
                    </Link>
                  )}

                {props.remove !== false && current.id && !disabled && (
                  <button
                    type="button"
                    className={cx("btn ms-3", {
                      "btn-danger": !current.deleted,
                      "btn-primary": current.deleted,
                    })}
                    onClick={toggle}
                  >
                    {t(current.deleted ? "restore" : "remove")}
                  </button>
                )}

                {changed && (
                  <button type="submit" className="btn btn-primary ms-3">
                    {t("save")}
                  </button>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Form>

      {events.data?.next?.map((event) => (
        <Event key={event.id} event={event} consumer={original}/>
      ))}

      {events.data?.past?.map((event) => (
        <Event key={event.id} event={event} consumer={original}/>
      ))}
    </>
  );
};

export default App;
